.wrapper {
  padding-top: 30px;
}

body {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
}

body h3 {
  margin-bottom: 25px;
}

.avatar {
  width: 70px;
  margin-right: 15px;
}

.navbar {
  z-index: 2;
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-brand a {
  color: #ffffff;
}

.nav-link {
  color: rgba(49, 53, 59, 0.96) !important;
  font-weight: 500;
}
.accordion-button {
  padding: 5px;
}
.accordion {
  margin-bottom: 5px;
}
.pmi-logo {
  width: 170px;
}
.form-wrapper,
.table-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.score__container {
  display: flex;
  justify-content: space-between;

}

.table-wrapper {
  max-width: 800px;
}

.edit-link {
  padding: 7px 10px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  margin-right: 10px;
  position: relative;
  top: 1px;
  text-decoration: none;
}

.edit-link:hover {
  text-decoration: none;
  color: #ffffff;
}

.App-header {
  height: 72px;
}

.bg-half {
  background: rgb(255 255 255 / 40%);
}

.bg-red {
  background: url("https://www.nawpic.com/media/2020/red-background-nawpic.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.top-50 {
  margin-top: 15%;
}

.bg-blue{
  overflow: hidden;
  background: #3399ff;
  width: 100%;
  height: calc(100vh - 72px);
  position: absolute;
  left: 0;
  right: 0;
  top: 72px;
}

.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
  z-index: 1;
}

.z-99 {
  z-index: 99;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}


.fadeOut {
  animation: fadeOutAnimation 1s;
}

@keyframes fadeOutAnimation {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.fadeIn {
  animation: fadeInAnimation 2s;
}

@keyframes fadeInAnimation {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media print {
  .noPrint{
    display:none;
  }
}